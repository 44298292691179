.airdrop {
  .controls {
    button {
      width: 100%;
    }
  }

  .airdrop-bg {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/images/airdrop.jpg");
    background-size: 100% 100%;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.5);
    background-blend-mode: multiply;
  }
}
