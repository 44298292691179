.swap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 600px;
  @media (max-width: 768px) {
    margin-bottom: 300px;
  }

  .card-header {
    margin-bottom: 20px;
    text-align: center;
  }

  .card {
    background-color: var(--card-background);
    max-width: 480px;
    width: 100%;
    border-radius: 20px;
    padding: 20px;
  }

  .cap {
    margin-bottom: 20px;
    text-align: center;

    p {
      font-size: 16px;
      color: #fff;
    }
  }

  .flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .countdown {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    border-radius: 20px;
    background-color: var(--drop-down-clr);
    padding: 10px;
    margin-bottom: 20px;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 5px;

      h4 {
        font-size: 24px;
      }

      span {
        font-size: 12px;
        text-transform: uppercase;
      }
    }
  }

  .progress-bar {
    background-color: var(--drop-down-clr);
    height: 12px;
    width: 100%;
    margin-bottom: 20px;
    border-radius: 20px;
    overflow: hidden;

    .progress-strip {
      height: 100%;
      width: 80%;
      background-color: var(--primary);
      border-radius: 20px;
    }
  }

  .tab {
    border-radius: 20px;
    padding: 5px;
    background-color: #000;
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    button {
      padding: 5px 16px;
      background-color: transparent;
      border-radius: 20px;
      color: #fff;
      border: none;
      font-size: 16px;
      cursor: pointer;

      &.active {
        background-color: grey;
      }
    }
  }

  .swap-form {
    width: 100%;

    .form {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .form-label {
        display: flex;
        flex-direction: column;
        gap: 5px;

        input {
          background-color: var(--drop-down-clr);
          border: none;
          outline: none;
          font-size: 20px;
          padding: 12px 24px;
          color: #fff;
          border-radius: 20px;
        }
      }
    }
  }

  .error-text {
    color: tomato;
  }
}
