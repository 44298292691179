.modal_backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px);
  z-index: 100;
  overflow-y: auto;
}

.base-modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 530px;
  width: 100%;

  @media (max-width: 576px) {
    max-width: 90%;
  }
}

.transaction-modal {
  background: var(--background);
  border-radius: 20px;
  color: #fff;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 200ms linear;

  // &.PENDING {
  //   border-color: orange;
  // }
  // &.ERROR {
  //   border-color: tomato;
  // }
  // &.SUCCESS {
  //   border-color: rgb(8, 225, 8);
  // }

  .transaction-modal-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    position: relative;

    .close-icon {
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);

      svg {
        width: 32px;
        height: 32px;
        path {
          stroke: #fff;
        }
      }
    }
  }

  .transaction-modal-content {
    padding: 30px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .icon {
      width: 80px;
      height: 80px;
      margin-bottom: 20px;

      &.PENDING {
        animation: rot 1s ease-in-out infinite;
        border: 7px solid var(--text-secondary-clr);
        border-top-color: transparent;
        border-radius: 50%;

        svg {
          display: none;
        }

        @keyframes rot {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }

      svg {
        width: 80px;
        height: 80px;
      }
    }

    p {
      font-size: 14px;
      line-height: 1.2;
    }
  }
}
